body {
  padding: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.flex {
  display: flex
}

.left-panel {
  margin-right: 2rem;
}

.right-panel {
  flex-grow: 1;
}

.ais-InfiniteHits {
  max-height: 68vh;
  overflow-y: scroll;
}

.ais-InfiniteHits-item {
  width: calc(50% - 1rem);
}

.hit-name {
  margin-bottom: 0.25rem;
}
